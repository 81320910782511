// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-js": () => import("/opt/build/repo/src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-when-js": () => import("/opt/build/repo/src/pages/when.js" /* webpackChunkName: "component---src-pages-when-js" */),
  "component---src-pages-who-js": () => import("/opt/build/repo/src/pages/who.js" /* webpackChunkName: "component---src-pages-who-js" */),
  "component---src-pages-why-js": () => import("/opt/build/repo/src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

